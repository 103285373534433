<template>
  <RCTextArea
    v-model="localValue"
    :label="label"
    :floatLabel="floatLabel"
    :disabled="disabled"
    :pattern="pattern"
    :maxlength="maxlength"
    @blur="$emit('blur', localValue)"
    @focus="$emit('focus', localValue)"
    @change="$emit('change', localValue)"
  />
</template>

<script>
import RCTextArea from "rcat-components/RCTextArea";

export default {
  name: "CustomRCTextArea",
  props: {
    // This props handles the v-model directive
    value: {
      required: false,
    },
    label: String,
    floatLabel: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    maxlength: {
      type: Number,
    },
  },
  components: {
    RCTextArea,
  },
  data() {
    return {
      localValue: this.value || "",
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.rc-textarea-container {
  width: 100%;
  border: 1px solid $color-border-container;
}
</style>
