export default {
  data() {
    return {
      reasonsDispute: [
        {
          text: this.$t("claimOrDispute.reasonClaimOption1"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption2"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption3"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption4"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption5"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption6"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption7"),
        },
        {
          text: this.$t("claimOrDispute.reasonClaimOption8"),
        },
      ],
    };
  },
};
