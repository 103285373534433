<template>
  <div class="load-history">
    <el-collapse v-model="openedTabs">
      <el-collapse-item title="History" name="history">
        <el-timeline>
          <el-timeline-item
            v-for="(history, index) in histories"
            :key="index"
            placement="top"
            :timestamp="getDate(history.createdAt) + ' ' + getHour(history.createdAt)"
          >
            <p class="load-history__item">
              <strong>{{ history.user }}</strong> {{ history.operationType.toLowerCase() }}d
              {{ history.entityType.toLowerCase() }}:
            </p>
            <ul class="changes-list">
              <li
                v-for="(change, index) in getChanges(
                  history.entityType.toLowerCase(),
                  history.changes,
                  history.operationType.toLowerCase()
                )"
                :key="index"
                class="changes-list__item"
              >
                <template v-if="Object.values(change)[0]">
                  <template
                    v-if="
                      history.operationType.toLowerCase() === 'create' &&
                      history.entityType.toLowerCase() === 'load'
                    "
                  >
                    <div v-for="(loadItem, index) in change.load" :key="'loadInfo-' + index">
                      <template v-if="Object.values(loadItem)[0]">
                        - <strong>{{ Object.keys(loadItem)[0] }}: </strong>
                        <span>{{ Object.values(loadItem)[0] }}</span>
                      </template>
                    </div>
                    <p class="section-subtitle">Line Items:</p>
                    <div
                      class="line-items-list"
                      v-for="(lineItem, index) in change.lineItems"
                      :key="'lineItem-' + index"
                    >
                      <hr v-if="index !== 0" class="line-items-divider" />
                      <div
                        v-for="(key, lineItemIndex) in Object.keys(lineItem)"
                        :key="lineItemIndex"
                      >
                        <strong>{{ key }}: </strong>
                        <span>{{ lineItem[key] }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    - <strong>{{ Object.keys(change)[0] }}: </strong>
                    <span>{{ Object.values(change)[0] }}</span>
                  </template>
                </template>
              </li>
            </ul>
          </el-timeline-item>
        </el-timeline>
        <div class="load-history__not-found" v-if="notFound">
          <p>{{ $t("myLoads.myLoadsDetail.nohistoriesFound") }}</p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

export default {
  name: "InfoHistory",
  props: {
    loadById: Object,
  },
  data() {
    return {
      openedTabs: [],
      histories: null,
      historyTime: null,
      users: null,
      notFound: null,
    };
  },
  computed: {
    ...mapGetters({
      cancellationStatus: "cancellation/getCancellationStatus",
      cancellationReason: "cancellation/getCurrentCancellationReason",
    }),
  },
  watch: {
    cancellationStatus: {
      handler() {
        const cancellationLog = {
          loadId: "a0jRc000000TMtDIAW",
          entityType: "Load",
          entityId: "a0jRc000000TMtDIAW",
          operationType: "Cancellation requeste",
          user: "guane-admin@guane.com.co",
          createdAt: "2023-09-21T20:37:32.018Z",
          changes: {
            "Load status": "Requested cancellation",
            "Cancellation reason": this.cancellationReason || "Freight not ready",
          },
        };
        this.histories.unshift(cancellationLog);
      },
      // deep: true,
    },
  },
  async created() {
    this.histories = await this.$store.dispatch("history/getAllHistory", this.loadById.id);
  },
  methods: {
    async getUserName() {
      if (this.$options.filters.checkPermission("get:users")) {
        this.users = await this.$store.dispatch("user/getUsers");
        for (let i = 0; i < this.histories.length; i += 1) {
          const index = this.users.findIndex((user) => user.id === this.histories[i].user);
          if (index !== -1) {
            this.histories[i].username = this.users[index].username;
          }
        }
      }
    },
    getDate(completeDate) {
      return dayjs(completeDate).format("MM/DD/YYYY");
    },
    getHour(completeDate) {
      return dayjs(completeDate).format("HH:mm");
    },
    getChanges(entity, history, operationType) {
      let changesObject;
      switch (entity) {
        case "stop":
          changesObject = this.getStopChanges(history);
          break;
        case "load":
          if (operationType === "create") {
            changesObject = this.getLoadChanges(history);
          } else {
            changesObject = this.getGeneralChanges(history);
          }
          break;
        default:
          changesObject = this.getGeneralChanges(history);
      }
      return changesObject;
    },
    getGeneralChanges(history) {
      const entries = Object.entries(history);
      const changes = entries.map((change) => {
        const [key, value] = change;
        return { [key]: value };
      });
      return changes;
    },
    getStopChanges(history) {
      let buildStopInfo = false;
      Object.keys(history).forEach((element) => {
        if (element.toLowerCase().match("city")) {
          buildStopInfo = true;
        }
      });
      if (buildStopInfo) {
        const stopType = Object.keys(history)[0].split(" ")[0];
        const changeValue = `${history[`${stopType} City`]}, ${
          history[`${stopType} State Province`]
        } ${history[`${stopType} Postal Code`]} ${history[`${stopType} Country`]}`;
        return [{ [stopType]: changeValue }];
      }
      return this.getGeneralChanges(history);
    },
    getLoadChanges(history) {
      const historyAux = JSON.parse(JSON.stringify(history));
      delete historyAux["Line Items"];
      const loadChanges = this.getGeneralChanges(historyAux);
      const changes = {
        load: loadChanges,
        lineItems: history["Line Items"],
      };
      return [changes];
    },
  },
};
</script>

<style lang="scss" scoped>
.load-history {
  &__item {
    margin: 0px;
  }
}

.changes-list {
  padding-left: 5px;
  &__item {
    list-style-type: none;
  }
}

.line-items-list {
  margin-left: 15px;
}

.line-items-divider {
  width: 45%;
  margin: 5px 0px;
  background: $color-gray;
}

.section-subtitle {
  margin: 3px 0px;
  font-weight: bold;
}

::v-deep {
  .el-collapse-item__header {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-weight: bold;
    margin: 2% 0;
    @media (max-width: 800px) {
      display: flex;
      justify-content: space-between;
      padding: 1% 7%;
      margin: 2% 0 0 0;
    }
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-small-standart-text;
    padding-bottom: 1%;
    @media (max-width: 800px) {
      padding: 1% 3%;
    }
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-timeline-item__tail {
    border-left: 2px solid $color-border-container;
  }
  .el-timeline-item__node {
    background-color: $color-border-container;
  }
  .el-timeline-item__content {
    text-align: left;
    color: $color-primary-company;
  }
  .el-timeline-item__timestamp.is-top {
    color: $color-primary-company;
    font-weight: 600;
    text-align: left;
  }
}
</style>
