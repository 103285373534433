<template>
  <div class="claim-dispute">
    <div class="claim-dispute__row">
      <div class="claim-dispute__column">
        <div class="claim-dispute__line"></div>
        <span> {{ $t("claimOrDispute.billingInquiryText") }} </span>
      </div>
      <div class="claim-dispute__column">
        <div class="claim-dispute__line"></div>
        <span> {{ $t("claimOrDispute.lostCargoText") }} </span>
      </div>
    </div>
    <div class="claim-dispute__row">
      <div class="claim-dispute__column">
        <button
          :name="billigInquiryOption"
          :class="billingInquiryButton"
          @click="showOrCreateInquiry(billigInquiryOption)"
        >
          {{ billingInquiryButton }}
        </button>
      </div>
      <div class="claim-dispute__column">
        <button
          :name="lostOrDamageOption"
          :class="lostOrDamageButton"
          @click="showOrCreateDispute(lostOrDamageOption)"
        >
          {{ lostOrDamageButton }}
        </button>
      </div>
    </div>
    <b-modal
      centered
      size="xl"
      v-model="createClaimDisputeModal"
      ref="claim-dispute"
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <CreateClaimOrDispute
        :loadById="loadById"
        :infoClaimOrDisp="infoClaimOrDisp"
        @changeButtonOption="changeButtonOption"
        @closeModal="closeModalClaimOrDispute"
        @claimOrDisputeCreated="$emit('claimOrDisputeCreated')"
      />
    </b-modal>
    <b-modal
      centered
      size="lg"
      v-model="detailClaimOrDispute"
      ref="detail-claim-dispute"
      hide-header
      hide-footer
      no-close-on-backdrop
      id="detail-claim-dispute"
    >
      <DetailClaimOrDispute
        v-if="infoClaimOrDisp"
        :loadById="loadById"
        @closeModal="closeDetailClaimOrDispute"
      />
    </b-modal>
  </div>
</template>

<script>
import CreateClaimOrDispute from "../CreateClaimOrDispute.vue";
import DetailClaimOrDispute from "../DetailClaimOrDispute.vue";

export default {
  name: "ClaimOrDispute",
  components: {
    CreateClaimOrDispute,
    DetailClaimOrDispute,
  },
  props: {
    loadById: Object,
    infoClaimOrDisp: Array,
  },
  data() {
    return {
      createClaimDisputeModal: false,
      detailClaimOrDispute: false,
      billigInquiryOption: "submitInquiry",
      lostOrDamageOption: "submitDamage",
      billingInquiryButton: this.$t("claimOrDispute.submitButton"),
      lostOrDamageButton: this.$t("claimOrDispute.submitButton"),
    };
  },
  watch: {
    infoClaimOrDisp: {
      immediate: true,
      deeper: true,
      handler() {
        if (this.infoClaimOrDisp.length > 0) {
          for (let i = 0; i < this.infoClaimOrDisp.length; i += 1) {
            const option = this.infoClaimOrDisp[i].category;
            this.changeButtonOption(option);
          }
        }
      },
    },
  },
  methods: {
    showOrCreateInquiry(id) {
      if (id === "submitInquiry") {
        this.createClaimDisputeModal = true;
        this.$store.commit("claims/setSelectedClaimOrDispute", id);
      } else {
        this.detailClaimOrDispute = true;
        this.$store.commit("claims/setReviewClaimOrDispute", id);
      }
    },
    showOrCreateDispute(id) {
      if (id === "submitDamage") {
        this.createClaimDisputeModal = true;
        this.$store.commit("claims/setSelectedClaimOrDispute", id);
      } else {
        this.detailClaimOrDispute = true;
        this.$store.commit("claims/setReviewClaimOrDispute", id);
      }
    },
    changeButtonOption(category) {
      if (category === "claim") {
        this.lostOrDamageButton = this.$t("claimOrDispute.reviewButton");
        this.lostOrDamageOption = "showLostOrDamage";
      } else {
        this.billingInquiryButton = this.$t("claimOrDispute.reviewButton");
        this.billigInquiryOption = "showBillingInquiry";
      }
    },
    closeModalClaimOrDispute() {
      this.createClaimDisputeModal = false;
    },
    closeDetailClaimOrDispute() {
      this.detailClaimOrDispute = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.claim-dispute {
  display: flex;
  flex-direction: column;
  text-align: left;
  // width: 100%;
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
    .Submit {
      @include submit-button();
      font-weight: bold;
      padding: 2% 5%;
      width: min-content;
    }
    .Review {
      @include review-button();
      font-weight: bold;
      padding: 2% 5%;
      width: min-content;
    }
  }
  &__line {
    border-top: 2px solid $color-border-container;
    margin-bottom: 10px;
    padding-top: 8px;
    width: 80%;
  }
  &__column {
    width: 50%;
    margin-right: 5%;
    span {
      font-weight: bold;
    }
  }
}

::v-deep #detail-claim-dispute .modal-content {
  width: 90%;
}

::v-deep .modal-content {
  width: 60%;
  // padding: 4% 4%;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .claim-dispute {
    padding: 0 7%;
    &__right,
    &__left {
      button {
        width: 90%;
      }
    }
  }
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
}
</style>
