<template>
  <div class="load-id-info">
    <div class="load-id-info__text">
      <div class="load-id-info__id">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.load") }}:
          {{ loadInformation.mode }}</span
        >
        <a
          v-if="isMagayaAccount"
          :href="loadById.magaya_record_url"
          target="_blank"
        >
          <i class="ion ion-open-outline"></i>
        </a>
      </div>
      <div class="load-id-info__pro-number">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.proNumber") }}:
        </span>
        <a v-if="linkTracking !== ''" target="_blank" :href="linkTracking"
          >{{ loadInformation.proNumber }}
        </a>
        <a v-if="linkTracking === ''" @click="undefinedLink()"
          >{{ loadInformation.proNumber }}
        </a>
      </div>
      <div class="load-id-info__pro-number">
        <span class="description-label__name"
          >Pickup number:
          {{ loadById.pickup_carrier_number }}
        </span>
      </div>
    </div>
    <div class="load-id-info__steps">
      <Steps
        :loadInformationSteps="loadInformation.loadStatus"
        :loadById="loadById"
      />
      <div class="load-action-buttons">
        <button
          v-loading="cloningLoad"
          @click="cloneLoad"
          class="load-action-buttons__clone-load"
        >
          <span class="clone-load__text">Clone Load</span>
        </button>
        <button
          v-loading="cloningLoad"
          @click="toggleCancelLoadModalVisibility"
          class="load-action-buttons__cancel-load"
          v-if="showCancellationButton"
        >
          <span class="clone-load__text">Cancel Pickup</span>
        </button>
        <div
          v-if="cancellationStatus.exist || loadCancelled"
          class="load-action-buttons__cancel-load-text"
        >
          <p>Cancellation requested</p>
        </div>
        <CustomModal
          v-model="cancelLoadModalVisibility"
          size="md"
          :centered="true"
          :showOverlay="cancellingLoad"
          :closeOnBackDrop="false"
          :closeOnEsc="false"
          modalTitle="Request pickup cancellation"
        >
          <template #modal-content>
            <LoadCancellationVue
              @cancelLoadModalClosed="toggleCancelLoadModalVisibility"
              @cancelLoad="cancelLoad"
            />
          </template>
        </CustomModal>
      </div>
    </div>
    <b-overlay :show="cloningLoad" rounded="sm" z-index="1200" no-wrap fixed>
      <template #overlay>
        <div class="loading-overlay">
          <b-icon icon="truck" animation="cylon" font-scale="4"></b-icon>
          <p>
            The characteristics of the current load are being cloned. This may
            take a moment.
          </p>
          <!-- <FadeAnimationTextArray :textArray="loadingTextArray" :duration="6000" /> -->
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
// import FadeAnimationTextArray from "@/components/FadeAnimationTextArray.vue";
import Steps from "./Steps.vue";
import CarrierTrackingLink from "../../../Extend/CarrierTrackingLink";
import CustomModal from "../../../components/CustomModal.vue";
import LoadCancellationVue from "./LoadCancellation.vue";

export default {
  name: "LoadIdInfo",
  components: {
    Steps,
    CustomModal,
    LoadCancellationVue,
    // FadeAnimationTextArray,
  },
  props: {
    loadInformation: Object,
    loadById: Object,
    isMagayaAccount: String,
    cancellationStatus: Object,
  },
  data() {
    return {
      cloningLoad: false,
      cancelLoadModalVisibility: false,
      cancellingLoad: false,
      loadCancelled: false,
    };
  },
  mixins: [CarrierTrackingLink],
  computed: {
    showCancellationButton() {
      return (
        this.loadInformation.loadStatus.toLowerCase() === "assigned" &&
        !this.cancellationStatus.exist &&
        !this.loadCancelled
      );
    },
    linkTracking() {
      const linkTrackingStatus = this.loadInformation.proNumber !== null ? this.searchTrackingLink(
        this.loadInformation.carrierInfo.name,
        this.loadInformation.proNumber
      ) : false;
      return linkTrackingStatus;
    },
  },
  methods: {
    async cloneLoad() {
      const cloneLoad = await this.swal({
        title: "Do you want to clone this load?",
        text: `A new load will be created with the same information
        (pickup and delivery locations, accessorials and items).`,
        icon: "warning",
        buttons: true,
      });
      if (cloneLoad) {
        this.cloningLoad = true;
        const newLoadId = await this.$store.dispatch("load/cloneLoad", {
          id: this.$route.params.loadId,
        });
        const { id } = newLoadId;
        this.resetChosenLoadVuexVariables();
        this.cloningLoad = false;
        this.$router.push({
          name: "CarriersInfo",
          params: {
            loadId: id,
            socket: true,
          },
        });
      }
    },
    toggleCancelLoadModalVisibility() {
      this.cancelLoadModalVisibility = !this.cancelLoadModalVisibility;
    },
    resetChosenLoadVuexVariables() {
      this.$store.commit("carriers/resetCarriersList");
      this.$store.commit("load/resetChosenload");
      this.$store.commit("load/stops/resetChosenStops");
      this.$store.commit("load/accessorials/resetChosenAccessorials");
      this.$store.commit("load/lineItems/resetSelectedLineItems");
    },
    async cancelLoad(data) {
      this.cancellingLoad = true;
      await this.$store.dispatch("cancellation/cancelLoad", {
        loadId: this.$route.params.loadId,
        body: {
          reason: data,
        },
      });
      this.cancellingLoad = false;
      this.cancelLoadModalVisibility = false;
      this.loadCancelled = true;
      this.confirmationMessage();
      this.$store.commit("cancellation/setCancellationStatus", true);
    },
    confirmationMessage() {
      this.swal({
        title: `Cancellation request sent!`,
        text: "Our support team will contact you soon.",
        icon: "success",
      });
    },
    undefinedLink() {
      this.swal({
        title: "Info",
        text: this.$t("myLoads.messageLinkCarrier"),
        icon: "warning",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.load-id-info {
  display: flex;
  justify-content: space-between;
  text-align: left;
  &__text {
    width: 30%;
  }
  &__id {
    @include font-section-title;
    font-weight: bold;
    a {
      margin-left: 5px;
      text-decoration: none;
      cursor: pointer;
      color: $color-primary-company-lighten;
    }
  }
  &__pro-number {
    font-style: italic;
  }
  &__steps {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.load-action-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  & button {
    min-width: 112px;
    max-width: 150px;
    padding: 5px 5px;
    font-weight: bold;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    outline: none;
  }
  &__clone-load {
    background-color: $color-primary-trigger-button;
    color: $color-primary-text-button;
    &:hover {
      background-color: $color-primary-trigger-button-hover;
    }
  }
  &__cancel-load {
    margin-top: 10px;
    background-color: $color-remove-inactive-button;
    color: $color-primary-text-button;
    @media (max-width: 800px) {
      margin-left: 10px;
    }
  }
  &__cancel-load-text {
    font-size: 14px;
    color: $color-remove-inactive-button;
    font-weight: bold;
    margin-top: 10px;
  }
}
@media (max-width: 800px) {
  .load-id-info {
    flex-direction: column;
    align-items: flex-start;
    &__text {
      width: 100%;
    }
    &__steps {
      margin-top: 5%;
      width: 100%;
    }
  }
}

.loading-overlay {
  max-width: 500px;
  text-align: center;
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

::v-deep {
  .el-loading-mask {
    border-radius: 6px;
    z-index: 1000;
  }
}
a:hover {
  color: $color-primary-company;
  text-decoration: underline;
}
a {
  color: $color-primary-company;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
</style>
