<template>
  <el-collapse v-model="openedTabs">
    <el-collapse-item :title="$t('myLoads.myLoadsDetail.loadDetails')" name="lineItems">
      <div class="lineItems-container">
        <div class="lineItems-group">
          <div class="lineItems-group__container-lineItems">
            <div
              v-for="(item, index) in selectedLineItems"
              class="lineItems-group__info"
              :key="index"
            >
              <div class="lineItems-group__title-container">
                <h6 class="lineItems-group__title">
                  {{ $t("myLoads.myLoadsDetail.line") }} {{ index + 1 }}
                </h6>
              </div>
              <span class="lineItems-group__text" v-if="item.description !== null">
                <b> {{ $t("myLoads.myLoadsDetail.description") }}: </b>
                {{ item.description }}</span
              >
              <span class="lineItems-group__text" v-if="item.handling_unit_count !== null">
                <b>{{ $t("myLoads.myLoadsDetail.handlingCount") }}: </b>
                {{ item.handling_unit_count }} ({{ item.handling_units }})</span
              >
              <span class="lineItems-group__text">
                <b> {{ $t("myLoads.myLoadsDetail.dimensions") }}: </b>
                {{ ceilDecimals(item.length) }} X {{ ceilDecimals(item.width) }} X
                {{ ceilDecimals(item.height) }} ({{ item.dimension_units }})
              </span>

              <span class="lineItems-group__text" v-if="item.weight !== null">
                <b> {{ $t("myLoads.myLoadsDetail.weight") }}: </b>
                {{ item.weight }} ({{ item.weight_units }})</span
              >
              <span class="lineItems-group__text" v-if="item.nmfc_number !== null">
                <b> NMFC number:</b>
                {{ item.nmfc_number }}({{ item.nmfc_class }})</span
              >
              <span class="lineItems-group__text" v-if="item.nmfc_class !== null">
                <b> {{ $t("myLoads.myLoadsDetail.nmfcClass") }}: </b>
                {{ item.nmfc_class }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "InfoLineItems",
  props: {
    loadById: Object,
  },
  data() {
    return {
      selectedLineItems: null,
      openedTabs: ["lineItems"],
    };
  },
  async created() {
    this.selectedLineItems = this.loadById.line_items;
  },
  methods: {
    ceilDecimals(decimal) {
      return Math.ceil(decimal);
    },
  },
};
</script>

<style lang="scss" scoped>
.lineItems-container {
  padding: 1% 0%;
  background-color: $color-gray-light;
  color: $color-primary-company;
  font-family: $font-family-portal;
}

.lineItems-group {
  &__title-container {
    text-align: start;
    margin-left: 15px;
  }
  &__info {
    display: grid;
    justify-items: flex-start;
  }
  &__text {
    text-align: left;
    margin-left: 15px;
  }
  &__title {
    margin: 15px 0px;
    color: $color-border-container;
    font-weight: bold;
  }
}

::v-deep {
  .el-collapse-item__header {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-weight: bold;
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    padding-bottom: 1%;
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
</style>
