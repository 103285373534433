export default {
  data() {
    return {
      reasonsClaims: [
        {
          text: this.$t("claimOrDispute.reasonDisputeOption1"),
        },
        {
          text: this.$t("claimOrDispute.reasonDisputeOption2"),
        },
        {
          text: this.$t("claimOrDispute.reasonDisputeOption3"),
        },
        {
          text: this.$t("claimOrDispute.reasonDisputeOption4"),
        },
        {
          text: this.$t("claimOrDispute.reasonDisputeOption5"),
        },
        {
          text: this.$t("claimOrDispute.reasonDisputeOption6"),
        },
        {
          text: this.$t("claimOrDispute.reasonDisputeOption7"),
        },
      ],
    };
  },
};
