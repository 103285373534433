<template>
  <div class="searching-truck-modal">
    <b-overlay rounded="sm">
      <div class="searching-truck-modal-content">
        <div class="searching-truck-modal__header">
          <div class="searching-truck-modal__title">
            <h3>We are looking for a truck</h3>
          </div>
          <!-- <div class="searching-truck-modal__button">
            <button @click="$emit('closeModal')" class="close-button">
              <i class="ion ion-close-circle-outline close-button__icon"></i>
            </button>
          </div> -->
        </div>
        <hr />
        <div class="searching-truck-modal__body">
          <p class="searching-truck-modal__text">
            We are actively looking for a truck! As soon as we find one, you will be able to
            download your Bill of Lading and Labels here!
          </p>
        </div>
        <div class="searching-truck-button">
          <button @click="$emit('closeModal')">
            Back
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SearchingTruckModal",
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.close-button {
  @include modals-close-button;
}
.searching-truck-modal {
  padding: 10px;
  &__header {
    @include modals-header;
  }
  &-content {
    font-family: $font-family-portal;
  }
  &__title {
    @include modals-title;
  }
  &__body {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    color: $color-primary-company;
  }
}
hr {
  margin: 0;
}
.searching-truck-button {
  text-align: center;
}
.searching-truck-button button {
  @include primary-button;
  padding: 7px;
  margin-top: 15px;
  width: 150px;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
</style>
