<template>
  <div class="row-steps">
    <ul class="steps">
      <li
        v-for="(step, index) in steps"
        :key="index"
        :class="[
          step.current ? 'is-active' : '',
          step.name === 'cancelled' ? 'cancelled' : '',
          step.name === 'cancelled' && step.current ? 'is-cancelled' : '',
          step.name === 'cancelled' && !step.current ? 'hide-cancelled' : '',
        ]"
        v-b-popover.hover.top="step.date"
        title=""
      >
        {{ $t("myLoads.myLoadsDetail." + step.name + "") }}
      </li>
    </ul>
  </div>
</template>

<script>
import LoadStatusType from "../../../Extend/LoadStatusType";

export default {
  name: "Steps",
  mixins: [LoadStatusType],
  props: {
    loadInformationSteps: String,
    loadById: Object,
  },
  data() {
    return {
      steps: [
        { name: "cancelled", current: false, date: "" },
        { name: "pending", current: false, date: "" },
        { name: "assigned", current: false, date: "" },
        { name: "inTransit", current: false, date: "" },
        { name: "delivered", current: false, date: "" },
      ],
    };
  },
  computed: {
    cancelled() {
      if (this.steps[0].current) {
        return "";
      }
      return "display: none;";
    },
  },
  watch: {
    loadInformationSteps: {
      immediate: true,
      async handler() {
        await this.updateLoadStatus();
      },
    },
  },
  methods: {
    async updateLoadStatus() {
      switch (this.loadInformationSteps) {
        case "Unassigned":
          this.pendingMode();
          break;

        case "Quotes Requested":
          this.pendingMode();
          break;

        case "Quotes Received":
          this.pendingMode();
          break;

        case "Quoted":
          this.pendingMode();
          break;

        case "Tendered":
          this.assignedMode();
          break;

        case "Assigned":
          this.assignedMode();
          break;

        case "Dispatched":
          this.assignedMode();
          break;

        case "In Transit":
          this.inTransitMode();
          break;

        case "Delivered":
          this.deliveredMode();
          break;

        case "Completed":
          this.deliveredMode();
          break;

        case "Cancelled":
          this.cancelledMode();
          break;

        case "Declined":
          this.cancelledMode();
          break;

        case "Expired":
          this.cancelledMode();
          break;

        default:
          this.steps[1].current = true;
          this.steps[1].date = this.loadById.request_history;
          break;
      }
    },
    pendingMode() {
      this.steps[1].current = true;
      this.steps[1].date = this.loadById.request_history;
    },
    assignedMode() {
      this.steps[2].current = true;
      this.steps[1].date = this.loadById.request_history;
      this.steps[2].date = this.loadById.tender_accepted_date.slice(0, 10);
      this.steps[3].date = this.loadById.expected_ship_date;
      this.steps[4].date = this.loadById.expected_delivery_date;
    },
    inTransitMode() {
      this.steps[3].current = true;
      this.steps[1].date = this.loadById.request_history;
      this.steps[2].date = this.loadById.tender_accepted_date.slice(0, 10);
      this.steps[3].date = this.loadById.actual_pickup_date;
      this.steps[4].date = this.loadById.expected_delivery_date;
    },
    deliveredMode() {
      this.steps[4].current = true;
      this.steps[1].date = this.loadById.request_history;
      this.steps[2].date = this.loadById.tender_accepted_date.slice(0, 10);
      this.steps[3].date = this.loadById.actual_pickup_date;
      this.steps[4].date = this.loadById.actual_delivery_date;
    },
    cancelledMode() {
      this.steps[0].current = true;
      this.steps[0].date = "Cancelled";
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0;
  .hide-cancelled {
    display: none;
  }
  // .cancelled {
  //   &:before {
  //     background-color: #b4b4b4;
  //     border: 3px solid #b4b4b4;
  //   }
  //   &::after {
  //     background-color: #b4b4b4;
  //   }
  // }
  > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: $color-primary-company;
    z-index: 1;
    @include font-small-standart-text;

    &:before {
      content: "\f00c";
      content: "\2713;";
      content: "\10003";
      content: "\10004";
      content: "";
      display: block;
      margin: 0 auto 4px;
      background-color: $color-steps-bar;
      width: 20px;
      height: 20px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border: 2px solid $color-steps-bar;
      border-radius: 50%;
    }
    &:after {
      content: "";
      height: 5px;
      width: 100%;
      background-color: $color-steps-bar;
      position: absolute;
      top: 8px;
      left: 50%;
      z-index: -1;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &.is-active {
      @extend %remain-steps;
      &:before {
        background-color: $color-steps-bar;
        border-color: $color-steps-bar;
      }
      ~ li {
        @extend %remain-steps;
        &:before {
          background-color: $color-white;
          border: 3px solid #b4b4b4;
        }
      }
    }
    &.is-cancelled {
      &:before {
        background-color: red;
        border-color: #b4b4b4;
      }
    }
  }
}

@media (max-width: 530px) {
  .steps {
    > li {
      font-size: 0.75rem;
    }
  }
}
@media (max-width: 470px) {
  .steps {
    > li {
      font-size: 0.563rem;
    }
  }
}
</style>
