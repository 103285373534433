<template>
  <div class="pay-button-container">
    <button
      class="pay-online-button ml-3"
      :class="{ 'background-color-pending': hasMelioData }"
      @click="payOnlineButton"
      :disabled="hasMelioData"
      v-loading="isLoading"
    >
      <span class="pay-online-button__text">
        {{ hasMelioData ? "" : "Pay Online" }}
        <i class="ion ion-card-sharp" v-if="!hasMelioData"></i>
      </span>
    </button>
    <div class="powered-by-container">
      <span class="powered-by-container__text">Powered by </span>
      <img class="icons-tools" src="@/assets/images/melio-logo-vector.svg" alt="" />
    </div>
    <button
      v-loading="isLoading"
      :disabled="hasMelioData"
      @click="payOnlineButton"
      class="float-button"
      :class="{ 'background-color-pending': hasMelioData }"
    >
      <i v-b-popover.hover.top="hasMelioData ? '' : 'Pay Online'" class="ion ion-card-sharp"></i>
    </button>
  </div>
</template>

<script>
import { warningMessage } from "../../../utils/ErrorMessages";

export default {
  name: "PayButton",
  props: {
    melioData: Array,
  },
  data() {
    return {
      tokenInfo: null,
      isLoading: false,
    };
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
  },
  computed: {
    hasMelioData() {
      return this.melioData.length < 0;
    },
  },
  methods: {
    async payOnlineButton() {
      if (!this.hasMelioData) {
        this.isLoading = true;
        this.$store.commit("paymentHub/setNewLoadPaymentData", this.melioData);
        this.$router.push(`/payment-hub`);
        this.isLoading = false;
      } else {
        warningMessage("Info", "Unable to redirect to payment hub");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.float-button {
  background-color: $color-primary-company;
  color: $color-white;
  position: fixed;
  z-index: 999;
  bottom: 1vw;
  right: 3vw;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-bottom: 0.6rem;
  text-align: center;
  transition: 0.3s;
  font-size: 22px;
  border: none;
}
/* Pay Online button */
.pay-button-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  align-items: flex-end;
}
.pay-online-button {
  width: 150px;
  border-radius: 15px;
  height: 40px;
  background: $color-primary-company;
  border: none;
  color: $color-white;
  &__text {
    font-size: 14px;
    font-weight: bold;
  }
  i {
    text-align: center;
  }
  @media (max-width: 500px) {
    margin-bottom: 5px;
  }
}
.powered-by-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-right: 4px;
}
.icons-tools {
  width: 50px;
  height: 50px;
}
/* Powered By Melo */
.powered-by-container__text {
  font-size: 12px;
  font-family: "Roboto", sans-serif !important;
  width: 75px;
  color: gray;
}
.pay-online-button ::v-deep .el-loading-mask {
  border-radius: 13px;
}
.float-button ::v-deep .el-loading-mask {
  border-radius: 50px;
}
.background-color-pending {
  background-color: #04366370;
}
</style>
