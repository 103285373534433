<template>
  <div class="load-detai-skeleton">
    <div class="load-info-skeleton">
      <div class="row">
        <div class="col-12 mb-3">
          <Skeleton width="10rem" class="mb-2"></Skeleton>
          <Skeleton width="10rem" class="mb-2"></Skeleton>
        </div>
        <div class="col-12">
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
        </div>
      </div>
      <div class="container">
        <div class="row mt-4 mb-4">
          <div class="col-6">
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="2.5rem" class="mb-4"></Skeleton>
            <Skeleton height="2.5rem" class="mb-4"></Skeleton>
          </div>
          <div class="col-6">
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="1.5rem" class="mb-4"></Skeleton>
            <Skeleton height="2.5rem" class="mb-4"></Skeleton>
            <Skeleton height="2.5rem" class="mb-4"></Skeleton>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
        </div>
        <div class="col-3">
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
        </div>
        <div class="col-3">
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
        </div>
        <div class="col-3">
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Skeleton width="" height="5rem" class="mb-3"></Skeleton>
        </div>
        <div class="col-12">
          <Skeleton width="" height="5rem" class="mb-3"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "LoadDetailSkeletonResponsive",
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
