<template>
  <div class="load-detai-skeleton">
    <div class="load-info-skeleton">
      <div class="row">
        <div class="col-3">
          <Skeleton width="" class="mb-2"></Skeleton>
          <Skeleton width="" class="mb-2"></Skeleton>
          <Skeleton width="" class="mb-2"></Skeleton>
        </div>
        <div class="col-9">
          <Skeleton width="" height="4rem"></Skeleton>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-5">
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
          <Skeleton width="" height="2rem" class="mb-3"></Skeleton>
        </div>
        <div class="col-7">
          <Skeleton width="" height="2rem"></Skeleton>
          <div class="row mt-3">
            <div class="col-12">
              <Skeleton width="" height="11rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Skeleton width="" height="6rem" class="mb-3"></Skeleton>
        </div>
        <div class="col-12">
          <Skeleton width="" height="6rem" class="mb-3"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "LoadDetailSkeleton",
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
