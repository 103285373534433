<template>
  <div class="load-cancellation">
    <div class="load-cancellation__message">
      <p>
        {{ cancellationMessage }}
      </p>
    </div>
    <div class="form-item">
      <el-select
        v-model="cancellationReason"
        placeholder="Select an option"
        :class="missingFields ? 'missing-fields' : ''"
      >
        <el-option
          label="⁠I found a better price with another company"
          value="⁠I found a better price with another company"
        ></el-option>
        <el-option
          label="The shipment is not ready and/or the shipment details have changed"
          value="The shipment is not ready and/or the shipment details have changed"
        ></el-option>
        <el-option
          label="⁠Pickup has been missed several days"
          value="⁠Pickup has been missed several days"
        ></el-option>
        <el-option
          label="I am not satisfied with the service"
          value="I am not satisfied with the service"
        ></el-option>
        <el-option label="Another reason" value="Another reason"></el-option>
      </el-select>
      <div
        class="text-area"
        :class="missingFields ? 'missing-fields' : 'text-area'"
        v-if="cancellationReason === 'Another reason'"
      >
        <RCTextArea v-model="cancellationReasonText" label="Type here the cancellation reason" />
      </div>
      <div class="important-text">
        <p class="text-styles">
          Please kindly note that when canceling the shipment, it is necessary to delete the BOL and
          inform the shipper.
        </p>
      </div>
    </div>
    <div class="load-cancellation__buttons">
      <button
        class="load-cancellation__buttons load-cancellation__buttons--cancel"
        @click="$emit('cancelLoadModalClosed')"
      >
        <span> Cancel </span>
      </button>
      <button
        class="load-cancellation__buttons load-cancellation__buttons--send"
        @click="sendCancellationRequest"
      >
        <span> Send </span>
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import RCTextArea from "../../../components/RCComponents/CustomRCTextarea.vue";

export default {
  name: "LoadCancellation",
  components: {
    RCTextArea,
  },
  data() {
    return {
      cancellationMessage:
        "The request is subject to review. Last-minute shipment cancellations could trigger cancellation fees. Our operations department will contact the carrier, confirm the cancellation, and inform you if there are any extra charges.",
      cancellationReason: "",
      cancellationReasonText: "",
      missingFields: false,
    };
  },
  watch: {
    cancellationReason(newVal) {
      if (newVal !== "Another reason") {
        this.cancellationReasonText = "";
      }
    },
  },
  methods: {
    sendCancellationRequest() {
      if (
        this.cancellationReason === "" ||
        (this.cancellationReason === "Another reason" && this.cancellationReasonText === "")
      ) {
        this.missingFields = true;
      } else {
        const reasonToSend =
          this.cancellationReasonText !== ""
            ? this.cancellationReasonText
            : this.cancellationReason;
        this.missingFields = false;
        this.$emit("cancelLoad", reasonToSend);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/RCComponents.scss";

.load-cancellation {
  font-family: "Montserrat";
  &__message {
    margin: 10px 0px;
    color: $color-primary-company;
    font-weight: normal;
    & p {
      margin: 0px;
    }
  }
  &__buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    & button {
      @extend %flex-center-center;
      padding: 5px 20px;
      width: 30%;
      height: 40px;
      border-radius: 13px;
      @include font-button-text;
      font-weight: 400;
    }
    &--send {
      background-color: $color-save-button;
      color: $color-white;
      border: none;
    }
    &--cancel {
      @include secondary-button();
      width: 30%;
    }
  }
}
.text-area {
  margin-top: 10px;
}

.important-text {
  padding-top: 5px;
}

.text-styles {
  font-size: 10px;
  font-weight: normal;
  color: $color-primary-company;
}

::v-deep {
  .missing-fields .el-input__inner {
    background-color: $missing-fields;
  }

  .missing-fields .rc-textarea-field {
    background-color: $missing-fields;
  }

  .rc-textarea-container {
    padding-top: 0px;
    border: none;
    background: none;
  }
  .rc-textarea-field {
    width: 100%;
    height: 100px;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 5px 7px;
    border: 1px solid $color-border-container;
  }
  .el-select {
    width: 100%;
  }
  .el-input__inner {
    border-radius: 10px;
    border: 1px solid $color-border-container;
    width: 100%;
  }
}
</style>
