export default {
  data() {
    return {
      categoryDisputes: [
        {
          text: this.$t("claimOrDispute.categoryclaimOption1"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption2"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption3"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption4"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption5"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption6"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption7"),
        },
        {
          text: this.$t("claimOrDispute.categoryclaimOption8"),
        },
      ],
    };
  },
};
