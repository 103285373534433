export default {
  data() {
    return {
      categoryClaims: [
        {
          text: this.$t("claimOrDispute.categoryDisputeOption1"),
        },
        {
          text: this.$t("claimOrDispute.categoryDisputeOption2"),
        },
        {
          text: this.$t("claimOrDispute.categoryDisputeOption3"),
        },
        {
          text: this.$t("claimOrDispute.categoryDisputeOption4"),
        },
        {
          text: this.$t("claimOrDispute.categoryDisputeOption5"),
        },
        {
          text: this.$t("claimOrDispute.categoryDisputeOption6"),
        },
        {
          text: this.$t("claimOrDispute.categoryDisputeOption7"),
        },
      ],
    };
  },
};
