<template>
  <div class="load-documents">
    <div class="load-documents__doc-viewer" v-if="documents && screenWidth">
      <DocumentTypeToolbar
        @buttonClicked="filterDocumentsByType"
        :role="role"
        :documents="documents"
        :invoiceDocument="invoiceDocument"
        :tokenInfo="tokenInfo"
        :chosenLoad="chosenLoad"
      />
      <div class="" v-loading="generateDocsLoading">
        <DocumentsViewer
          :showButtonToolbar="enablePreviewToolbar"
          :previewMode="previewMode"
          :tokenInfo="tokenInfo"
          :page="page"
          :numPage="numPage"
          :totalPage="totalPage"
          :currentPage="currentPage"
          :disable="disable"
          :isResponsiveMode="isResponsiveMode"
          :src="selectedDocument"
          @documentOpened="openDocument"
          @viewerHidden="hidePdfViewer"
          @enableScroll="enableScroll"
          @disableScroll="disableScroll"
          @downloadSelectedDocument="downloadSelectedDocument"
          @shareDocument="shareDocument"
          @previous="previous"
          @next="next"
        >
          <template v-slot:viewer-render>
            <div class="not-found" v-if="notFoundFiles">
              <el-empty :description="notFoundDocumentsMessage"></el-empty>
            </div>
            <template v-if="showDocumentsList && previewMode">
              <DocumentsList
                :tokenInfo="tokenInfo"
                :documents="filteredDocuments"
                :currentType="currentType"
                @getSelectedDocument="getSelectedDocument"
                @disableScroll="disableScroll"
                @shareDocument="shareDocument"
              />
            </template>
            <template v-else-if="selectedDocument">
              <pdf
                v-if="selectedDocument && !isResponsiveMode && !isImageDocument"
                :src="selectedDocument"
                @num-pages="totalPage = $event"
                @page-loaded="currentPage = $event"
                :page="page"
              />
              <img
                v-if="selectedDocument && !isResponsiveMode && isImageDocument"
                style="width: 80%"
                :src="selectedDocument"
                alt=""
              />
            </template>
          </template>
        </DocumentsViewer>
      </div>
      <div class="load-documents__generate" v-if="showGetDocumentsButton">
        <button
          @click="generateDocuments()"
          :disabled="generateDocsLoading"
          v-if="!generateDocsLoading"
        >
          <span>Generate Documents</span>
        </button>
      </div>
    </div>
    <CustomModal
      :centered="true"
      :no-close-on-backdrop="true"
      size="md"
      modalTitle="Share document"
      v-model="shareDocumentModal"
    >
      <template #modal-content>
        <ShareDocument
          :documentType="currentType"
          :documentInformation="shareDocumentModalUrl"
          @closeShareDocumentModal="closeShareDocumentModal"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
import { mapGetters } from "vuex";
import pdf from "vue-pdf";
import DocumentsViewer from "@/components/DocumentsViewer.vue";
import OnResize from "@/Extend/onResize";
import downloadDocument from "@/utils/DownloadDocuments";
import GetDocumentName from "@/Extend/GetDocumentName";
import ShareDocument from "@/components/ShareDocument.vue";
import DocumentsList from "./DocumentsList.vue";
import DocumentTypeToolbar from "./DocumentTypeToolbar.vue";
import CustomModal from "../../../../components/CustomModal.vue";

export default {
  name: "LoadDocuments",
  mixins: [OnResize, GetDocumentName],
  components: {
    DocumentTypeToolbar,
    DocumentsList,
    ShareDocument,
    CustomModal,
    DocumentsViewer,
    pdf,
  },
  props: {
    documents: Array,
    chosenLoad: Object,
    changePoNumber: Boolean,
    generateDocsLoading: Boolean,
  },
  data() {
    return {
      filteredDocuments: [],
      selectedDocument: null,
      previewMode: false,
      currentType: "",
      shareDocumentModal: false,
      shareDocumentModalUrl: null,
      role: null,
      imagesExtension: [".png", ".jpg", ".jpeg", ".tiff"],
      page: 1,
      numPage: 0,
      totalPage: 0,
      currentPage: 0,
      disable: false,
      parcelModeId: "a0k1I00000B6pAYQAZ",
      loadById: null,
    };
  },
  watch: {
    deep: true,
    documents() {
      this.$emit("handleLoading", false);
      if (this.documents.length) {
        const tab = {
          type: this.currentType,
          previewMode: true,
          executedGenerateDocument: false,
        };
        this.filterDocumentsByType(tab);
      }
    },
  },
  computed: {
    ...mapGetters({
      tokenInfo: "login/getTokenInfo",
      invoiceDocument: "load/docsGenerate/getInvoiceDocument",
    }),
    showGetDocumentsButton() {
      const bolExists = this.documents.some((doc) => doc.url.includes("bol"));
      const labelsExist = this.documents.some(
        (doc) => doc.url.includes("shiping-labels") || doc.url.includes("shipping_labels")
      );
      return (
        !bolExists &&
        !labelsExist &&
        (this.currentType === "allDocs" || this.currentType === "/portal-tender-documents/")
      );
    },
    showDocumentsList() {
      if (this.filteredDocuments.length === 0) {
        return false;
      }
      if (this.isResponsiveMode) {
        return true;
      }
      if (this.currentType === "allDocs" || this.currentType === "/portal-tender-documents/") {
        return true;
      }
      return false;
    },
    notFoundFiles() {
      return this.filteredDocuments.length === 0;
    },
    isImageDocument() {
      return this.imagesExtension.some((extension) => this.selectedDocument.includes(extension));
    },
    enablePreviewToolbar() {
      const validTypes = [
        "/bol/",
        "labels",
        "DELIVERY_RECEIPT",
        "WEIGHT_CERTIFICATE",
        "invoice",
        "BILL_OF_LADING",
      ];
      return validTypes.includes(this.currentType);
    },
    isResponsiveMode() {
      if (this.screenWidth <= 800) {
        return true;
      }
      return false;
    },
    notFoundDocumentsMessage() {
      if (this.currentType === "DELIVERY_RECEIPT") {
        return "There is no POD available at this moment. Carriers will take a couple of days to upload documents; the POD will be available shortly.";
      }
      return "There are no available documents at this moment.";
    },
  },
  created() {
    this.role = this.$store.getters["login/getTokenInfo"].role.toLowerCase();
  },
  methods: {
    async filterDocumentsByType(payload) {
      this.currentType = payload.type;
      this.selectedDocument = null;
      this.previewMode = payload.previewMode;
      if (payload.type === "invoice") {
        this.selectedDocument = this.convertBase64ToBlob(this.invoiceDocument[0].file);
        this.filteredDocuments = this.invoiceDocument;
        return;
      }
      this.filterDocumentsByUrl(payload.type);
      this.selectDocumentByType(payload.type);
      if (payload.type === "DELIVERY_RECEIPT") {
        payload.type = "pod";
      }
      await this.generateDocsWithTheTabSelector(payload.executedGenerateDocument, payload.type);
    },
    filterDocumentsByUrl(urlType) {
      if (urlType === "allDocs") {
        this.filteredDocuments = this.$deepCopy(this.documents);
        this.addInvoiceDocInAllDocs();
        this.validateParcelToPodDoc();
        return;
      }
      this.filteredDocuments = this.documents.filter((el) => el.url && el.url.includes(urlType));
    },
    selectDocumentByType(urlType) {
      const documentByType = this.documents.find(
        (el) => el.url && el.url.includes && el.url.includes(urlType)
      );
      const selectedDocumentUrl = documentByType ? documentByType.url : "";
      this.getSelectedDocument(selectedDocumentUrl);
    },
    async generateDocuments(fileType) {
      this.$emit("handleLoading", true);
      if (this.$options.filters.checkPermission("get:docs-generate")) {
        const payload = {
          name: this.chosenLoad.name,
          id: this.chosenLoad.id,
        };
        if (fileType) {
          payload.document = fileType.replace(/\//g, "");
        }
        const response = await this.$store.dispatch(
          "load/docsGenerate/generateDocsByLoadId",
          payload
        );
        if (!fileType) {
          this.$emit("handleChangePoNumber", false);
          this.$emit("triggerGetDocuments");
        }
        return response;
      }
      return [];
    },
    async generateDocsWithTheTabSelector(executedGenerateDocument, type) {
      if (executedGenerateDocument && this.filteredDocuments.length === 0) {
        const response = await this.generateDocuments(type);
        const documentsUrl = this.$deepCopy(
          Object.values(response).map((item) => ({ url: item.url }))
        );
        if (type === "/bol/" || type === "labels") {
          this.$store.commit("load/docsGenerate/setDocument", documentsUrl);
        } else {
          documentsUrl.forEach((item) => {
            this.$store.commit("load/docsGenerate/addDocument", item);
          });
        }
        this.$emit("handleLoading", false);
      }
    },
    getSelectedDocument(url, openPdf) {
      if (this.isResponsiveMode) return;
      this.selectedDocument = url;
      this.shareDocumentModalUrl = url;
      if (openPdf) {
        this.previewMode = false;
      }
    },
    convertBase64ToBlob(document) {
      const base64Content = document.replace(/^data:application\/pdf;base64,/, "");
      const byteArray = new Uint8Array(
        atob(base64Content)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);
      return pdfUrl;
    },
    downloadSelectedDocument() {
      if (this.currentType === "invoice") {
        downloadDocument(this.selectedDocument, this.invoiceDocument[0].name);
        return;
      }
      const documentName = this.getDocumentName(this.selectedDocument);
      downloadDocument(this.selectedDocument, documentName);
    },
    openDocument() {
      this.previewMode = false;
    },
    hidePdfViewer() {
      this.previewMode = true;
    },
    shareDocument(item) {
      this.shareDocumentModal = true;
      this.previewMode = true;
      if (item) {
        this.shareDocumentModalUrl = item;
        return;
      }
      const foundDocument = this.documents.find(
        (document) => document.url === this.selectedDocument
      );
      if (foundDocument) {
        this.shareDocumentModalUrl = foundDocument;
      } else {
        [this.shareDocumentModalUrl] = this.invoiceDocument;
      }
    },
    addInvoiceDocInAllDocs() {
      if (this.invoiceDocument.length > 0) {
        const invoiceDoc = {
          name: this.invoiceDocument[0].name,
          url: this.convertBase64ToBlob(this.invoiceDocument[0].file),
          bucketName: `invoice/${this.$route.params.loadId}`,
        };
        this.filteredDocuments.push(invoiceDoc);
      }
    },
    validateParcelToPodDoc() {
      if (this.chosenLoad.mode_id === this.parcelModeId) {
        this.filteredDocuments = this.filteredDocuments.filter((doc) => !doc.name.includes("bol"));
      }
    },
    closeShareDocumentModal() {
      this.shareDocumentModal = false;
      this.shareDocumentModalUrl = null;
    },
    disableScroll() {
      document.body.style.overflow = "hidden";
      document.body.style.userSelect = "none";
    },
    enableScroll() {
      document.body.style.overflow = "auto";
      document.body.style.userSelect = "auto";
    },
    previous() {
      this.page -= 1;
    },
    next() {
      this.page += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.load-documents {
  width: 100%;
  &__doc-viewer {
    margin-top: 20px;
  }
  &__viewer {
    overflow: scroll;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 15px;
    background-color: $color-white;
    height: 310px;
    position: relative;
  }
  &__generate {
    button {
      @include primary-button(35px);
      padding: 7px;
      margin: 15px auto;
    }
  }
}
.previewer {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
// Modal styles
.pdf-viewer {
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.4);
  // border-radius: 15px;
  width: 100%;
  right: 0;
  top: 0;
  overflow: auto;
  height: 100vh;
  z-index: 999;
}
@media (max-width: 800px) {
  .load-documents {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 7%;
    &__doc-viewer {
      display: block;
    }
    &__viewer {
      box-shadow: 0px 0px 6px #00000029;
      border-radius: 15px;
      background-color: $color-white;
      height: min-content;
      position: relative;
    }
  }
}

::v-deep {
  .modal-content {
    width: 100%;
  }
}
::v-deep {
  .el-loading-mask {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.99);
  }
  .bg-light {
    background-color: $color-gray-light !important;
  }
}

::v-deep .not-found {
  .el-empty {
    padding: 40px 10px;
  }
  @media (max-width: 800px) {
    .el-empty {
      padding: 15px 10px 0px 10px;
    }
    .el-empty__description {
      p {
        font-size: 12px;
      }
    }
    svg {
      width: 44%;
    }
  }
}
</style>
