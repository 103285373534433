<template>
  <el-collapse v-model="openedTabs">
    <el-collapse-item
      :title="$t('myLoads.myLoadsDetail.carrierQuotesDetails')"
      name="carriersQuotesResponsive"
    >
      <div v-if="data.length > 0">
        <div v-for="(carriersQuotes, index) in data" :key="index">
          <div :class="carriersQuotes.principalCarrier ? 'quoted' : 'carriers-quotes-container'">
            <div class="carriers-quotes-container__info">
              <span>
                <b> Carrier: </b>
                <p class="text" :title="carriersQuotes.carrier">
                  {{ carriersQuotes.carrier }}
                </p>
              </span>
              <span>
                <b> Service Class: </b>
                <p class="text" :title="carriersQuotes.serviceClass">
                  {{ carriersQuotes.serviceClass }}
                </p>
              </span>
            </div>
            <div class="carriers-quotes-container__info">
              <span>
                <b> Transit Time: </b>
                {{ carriersQuotes.transitTime }}
              </span>
              <span>
                <b> Expiration Dates: </b>
                {{ carriersQuotes.expiration }}
              </span>
              <span>
                <b> Price: </b>
                {{ carriersQuotes.price | priceToUsd }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="carriers-not-found">
        <i class="ion ion-file-tray-outline carriers-not-found__icon"></i>
        <p class="carriers-not-found__text">No data were found.</p>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "InfoCarriersQuoteResponsive",

  data() {
    return {
      openedTabs: [],
      data: [],
    };
  },
  async created() {
    const carriersData = await this.$store.dispatch(
      "carriers/getAllOptionsCarriers",
      this.$route.params.loadId
    );
    this.data = carriersData.carriers;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
}
.carriers-quotes-container {
  display: flex;
  padding: 10px;
  border-bottom: 3px solid #ccc;
  color: $color-primary-company;
  font-family: $font-family-portal;
  justify-content: space-around;
  background-color: white;
  border-radius: 23px;
  margin: 15px 0px;
  text-align: start;
  &__info {
    display: grid;
  }
}

.quoted {
  @extend .carriers-quotes-container;
  background-color: #15486f2e;
  position: relative;
}

.quoted:hover::after {
  content: "This carrier is the carrier QUOTED";
  display: block;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.carriers-not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 150px;
  &__icon {
    font-size: 2rem;
  }
  &__text {
    font-size: 1.1rem;
  }
}
::v-deep {
  .el-collapse-item__header {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-weight: bold;
    padding: 1% 7%;
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    padding-bottom: 1%;
  }
  .el-collapse-item__wrap {
    width: 100%;
    background-color: transparent;
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
</style>
