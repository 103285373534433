<template>
  <b-overlay :show="loading" rounded="sm" class="overlay">
    <div class="create-claim-dispute">
      <div class="create-claim-dispute__content">
        <div class="create-claim-dispute__header">
          <div class="create-claim-dispute__title">
            <h3>{{ title }}</h3>
          </div>
          <div class="create-claim-dispute__button">
            <button class="close-button" @click="$emit('closeModal')" type="button">
              <i class="close-button__icon ion ion-close-circle-outline"></i>
            </button>
          </div>
        </div>
        <hr />
        <div class="input-container" :class="emptyFields['motive'] === true ? 'missing-field' : ''">
          <Dropdown
            v-model="modalForm.motive"
            class="input-information"
            :class="emptyFields['motive'] === true ? 'missing-field' : ''"
            :options="selectTypeProblem"
            optionLabel="text"
            :placeholder="$t('claimOrDispute.disputeOrClaim')"
            @change="handleMissingStyles($event, 'motive')"
          />
        </div>
        <b-form-textarea
          id="textarea"
          v-model="modalForm.text"
          :placeholder="$t('claimOrDispute.supportingText')"
          :class="emptyFields['text'] === true ? 'missing-field' : ''"
          rows="3"
          no-resize
          @input="handleMissingStyles($event, 'text')"
          :maxlength="inputLimits.reason.max"
          :minlength="inputLimits.reason.min"
        ></b-form-textarea>
        <div class="documents-information__text">
          <span>Add below additional emails to receive updates about the case.</span>
        </div>
        <div class="input-container__emails">
          <Chips
            v-model.trim="modalForm.emails"
            class="input-information-email"
            :placeholder="$t('claimOrDispute.email')"
            :suggestions="chipsEmails"
            @input="chipsSelected, handleMissingStyles($event, 'emails')"
          />
        </div>
        <div class="documents-information">
          <span class="documents-information__title">{{ $t("claimOrDispute.documents") }}</span>
          <span class="documents-information__text">
            {{ $t("claimOrDispute.include2Documents") }}
          </span>
        </div>
        <div class="claims-dispute-list">
          <ul class="claims-dispute-list__text">
            <li>Commercial invoice of the whole shipment</li>
            <li>Pictures of how cargo looked like (Shortage)</li>
            <li>Central Transport Customer Inspection Form</li>
            <li>Pictures of cargo after repacking/repairs labor</li>
          </ul>
          <ul class="claims-dispute-list__text">
            <li>GLT Form filled out</li>
            <li>Packing List</li>
            <li>Pictures showing internal damages (Damages)</li>
            <li>Invoice showing Repacking/Repairs labor.</li>
          </ul>
        </div>
        <div class="documents-information">
          <button
            v-if="documentsMessage"
            class="documents-information__button-download"
            @click="downloadClaim()"
          >
            <b-icon
              class="documents-information__button-download__icon"
              icon="cloud-download"
              font-scale="1.2"
            >
            </b-icon>
            {{ $t("claimOrDispute.downloadClaim") }}
          </button>
          <div class="validation" v-if="fileCategoriesValidation === false">
            <span class="validation__text"> *You must include at least 2 documents. </span>
          </div>
        </div>
        <DragAndDrop
          :files="files"
          :selectTypeProblem="selectTypeProblem"
          :selectDocuments="selectDocuments"
          :documentsCategory="documentsCategory"
          ref="uploadFiles"
        />
        <!-- <div class="d-flex justify-content-center">
          <button
            type="submit"
            class="create-claim-dispute__content--button"
            @click="createClaimOrDispute"
          >
            {{ $t("claimOrDispute.accept") }}
          </button>
        </div> -->
        <div class="button-container">
          <button
            type="submit"
            class="create-claim-dispute__content--button"
            @click="createClaimOrDispute"
          >
            {{ $t("claimOrDispute.accept") }}
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import Dropdown from "primevue/dropdown";
import ValidateEmptyFields from "../../utils/validateEmptyFields";
import Chips from "../../components/Chips.vue";
import onReSize from "../../Extend/onResize";
import DragAndDrop from "../../components/DragAndDropFiles.vue";
import ReasonsClaims from "../../Extend/ReasonsDispute";
import ReasonsDispute from "../../Extend/ReasonsClaims";
import CategoryClaims from "../../Extend/CategoryClaims";
import CategoryDisputes from "../../Extend/CategoryDisputes";
import ValidateEmail from "../../utils/EmailValidation";
import { errorMessage } from "../../utils/ErrorMessages";
import StorageEmails from "../../Extend/StorageEmails";

export default {
  name: "CreateClaimOrDispute",
  emits: ["closeModal", "changeButtonOption"],
  mixins: [
    onReSize,
    CategoryClaims,
    CategoryDisputes,
    ReasonsClaims,
    ReasonsDispute,
    StorageEmails,
  ],
  props: {
    loadById: Object,
    infoClaimOrDisp: Array,
  },
  components: {
    DragAndDrop,
    Dropdown,
    Chips,
  },
  data() {
    return {
      loading: false,
      claimOrDispute: null,
      fileCategoriesValidation: null,
      category: null,
      title: null,
      files: [],
      documentsMessage: null,
      documentsCategory: [],
      emails: [],
      formData: null,
      selectTypeProblem: [],
      selectDocuments: [],
      modalForm: {
        motive: null,
        text: null,
        emails: [],
        documentsCategory: {
          text: null,
        },
      },
      emptyFields: {
        motive: false,
        text: false,
      },
      emptyCategories: [],
      remountCategories: 0,
      chipsEmails: [],
      inputLimits: {
        reason: {
          min: 1,
          max: 50
        },
        category: {
          min: 1,
          max: 50
        },
        load_id: {
          min: 1,
          max: 50
        },
        shp: {
          min: 1,
          max: 50
        },
        status: {
          min: 1,
          max: 50
        },
      }
    };
  },
  created() {
    // "Any billing inquiry" refers to dispute category
    // "Lost or damage cargo" refers to claim category
    // eslint-disable-next-line operator-linebreak
    this.chipsEmails = JSON.parse(localStorage.getItem("portal-emails")) || [];
    this.claimOrDispute = this.$store.getters["claims/getSelectedClaimOrDispute"];
    switch (this.claimOrDispute) {
      case "submitInquiry":
        this.title = this.$t("claimOrDispute.submitInvoiceInquiry");
        this.category = "dispute";
        this.selectTypeProblem = this.reasonsClaims;
        this.documentsMessage = false;
        this.selectDocuments = this.categoryClaims;
        break;
      default:
        this.title = this.$t("claimOrDispute.lostDamaged");
        this.category = "claim";
        this.selectTypeProblem = this.reasonsDispute;
        this.documentsMessage = true;
        this.selectDocuments = this.categoryDisputes;
        break;
    }
  },
  methods: {
    validationCreateClaimDispute() {
      this.resetEmptyFields();
      this.deleteInvalidEmails();
      const formObject = { ...this.modalForm };
      formObject.motive = !this.modalForm.motive
        ? this.modalForm.motive
        : this.modalForm.motive.text;
      delete formObject.documentsCategory;
      delete formObject.emails;
      const formValidation = new ValidateEmptyFields(formObject);
      formValidation.emptyFields.forEach((key) => {
        this.emptyFields[key] = true;
      });
      this.fileCategoriesValidation = this.$refs.uploadFiles.validateFileCategories(this.files);
      if (this.fileCategoriesValidation && formValidation.validation) {
        return true;
      }
      return false;
    },
    resetEmptyFields() {
      Object.keys(this.emptyFields).forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
    async createClaimOrDispute() {
      if (this.$options.filters.checkPermission("post:claim")) {
        if (this.validationCreateClaimDispute()) {
          this.loading = true;
          this.formData = new FormData();
          this.formData.append("load_id", this.$route.params.loadId);
          this.formData.append("shp", this.loadById.name);
          this.formData.append("reason", this.modalForm.motive.text);
          this.formData.append("text", this.modalForm.text);
          this.formData.append("category", this.category);
          this.files.forEach((file) => {
            this.formData.append("raw_files", file.raw);
            this.formData.append("files_category", file.category);
          });
          this.modalForm.emails.forEach((email) => {
            this.formData.append("emails", email);
          });
          const response = await this.$store.dispatch("claims/createClaims", this.formData);
          this.updateClaimOrDispute(response);
          this.saveEmailsInLocalStorage(this.modalForm.emails);
          this.loading = false;
          this.$emit("closeModal");
          this.$emit("claimOrDisputeCreated");
        } else {
          errorMessage(this.$t("claimOrDispute.missingFields"));
        }
      }
    },
    updateClaimOrDispute(data) {
      this.infoClaimOrDisp.push(data.created_claim);
      this.$store.commit("claims/setInfoClaimOrDispute", this.infoClaimOrDisp);
      if (data.created_claim.category === "claim") {
        this.$emit("changeButtonOption", "claim");
      } else {
        this.$emit("changeButtonOption", "dispute");
      }
    },
    deleteInvalidEmails() {
      this.modalForm.emails = this.modalForm.emails.filter((email) => ValidateEmail(email));
    },
    chipsSelected() {
      this.modalForm.emails = { ...this.emails };
    },
    downloadClaim() {
      window.open(this.$t("claimOrDispute.urlDownloadClaim"));
    },
  },
};
</script>

<style lang="scss" scoped>
///****Import styles****\\\\
@import "../../assets/scss/components/Dropdown.scss";

.create-claim-dispute {
  width: 100%;
  font-family: $font-family-portal;
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
  }
  &__content {
    &--button {
      @include primary-button(35px);
      border-radius: 8px;
      padding: 7px 5px;
      margin-top: 20px;
      width: 20%;
      @include font-button-text;
      font-weight: 400;
    }
  }
}
.quote-acceptance__title {
  width: 100%;
  h3 {
    margin: 0;
  }
}
hr {
  width: 100%;
  margin-top: 0;
}
.close-button {
  @include modals-close-button;
}
.file-container {
  width: 100%;
  margin: 1rem 0;
}
.desktop-view {
  width: 100%;
}
.mobile-view {
  width: 100%;
}
.documents-name {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  @include font-standart-text;
  padding: 0 10px;
  &__file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.file-container-mobile {
  background-color: $color-white;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  height: 30px;
  width: 100%;
  margin: 2rem 0 1rem 0;
}
.input-container {
  @include input-information;
  border: 1px solid $color-border-container;
  height: 40px;
  width: 100%;
  padding: 7px 0px 5px 5px;
  box-shadow: none;
  border-radius: 10px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
  &__emails {
    @include input-information;
    border: 1px solid $color-border-container;
    min-height: 40px;
    height: auto;
    width: 100%;
    padding: 0px 0px 0px 5px;
    margin: 0;
    box-shadow: none;
    border-radius: 10px;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
}
.input-documents {
  @include input-information;
  border: 1px solid $color-border-container;
  padding: 7px 0px 5px 5px;
  height: 30px;
  width: 85%;
  box-shadow: none;
  border-radius: 10px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  color: $color-primary-company;
}

.input-information-email {
  @include font-small-standart-text;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
}

::placeholder {
  color: $color-gray-dark;
}

.overlay {
  color: $color-primary-company;
}

.documents-uploaded {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__input {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__name {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.documents-information {
  margin: 20px 0 10px 0;
  width: 100%;
  @include font-standart-text;
  &__title {
    font-weight: 600;
  }
  &__download-message {
    color: $color-border-container;
  }
  &__button-download {
    border-radius: 15px;
    border: none;
    background-color: var(--color-primary-trigger-button);
    color: #fff;
    height: 35px;
    width: 30%;
    margin: auto;
    font-weight: bold;
    padding: 7px 5px;
    margin-top: 20px;
    font-size: 14.5px;
    font-weight: 600;
    font-weight: 400;
    &__icon {
      font-weight: 600;
    }
  }
  &__form {
    font-weight: 600;
    a:link,
    a:visited,
    a:active {
      text-decoration: none;
      color: $color-border-container;
    }
  }
  &__text {
    padding-top: 10px;
    display: inline-block;
    @include font-standart-text;
  }
}

.claims-dispute-list {
  display: grid;
  grid-template-columns: 50% 50%;
  @include font-standart-text;
  &__text {
    width: 92%;
    padding-left: 12px;
    margin-bottom: 0;
    @media (max-width: 800px) {
      padding-left: 0.4rem;
    }
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.validation {
  position: relative;
  &__text {
    color: red;
    font-size: 0.8rem;
    position: absolute;
    top: -4px;
  }
}
///****Text styles****\\\\
::v-deep .form-control {
  margin: 10px 0px;
  border-radius: 10px;
  border: 1px solid $color-border-container;
  @include font-small-standart-text;
  color: $color-primary-company;
}

::v-deep .form-control:focus {
  box-shadow: none;
  border-color: $color-border-container;
  color: $color-primary-company;
  @include font-small-standart-text;
}
::-webkit-scrollbar {
  display: none;
}
::v-deep .el-upload-dragger {
  margin-top: 15px;
}
.missing-field {
  background: $missing-fields;
}

::v-deep .missing-field .chips-container__input {
  background-color: $missing-fields;
}
</style>
