var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"load-history"},[_c('el-collapse',{model:{value:(_vm.openedTabs),callback:function ($$v) {_vm.openedTabs=$$v},expression:"openedTabs"}},[_c('el-collapse-item',{attrs:{"title":"History","name":"history"}},[_c('el-timeline',_vm._l((_vm.histories),function(history,index){return _c('el-timeline-item',{key:index,attrs:{"placement":"top","timestamp":_vm.getDate(history.createdAt) + ' ' + _vm.getHour(history.createdAt)}},[_c('p',{staticClass:"load-history__item"},[_c('strong',[_vm._v(_vm._s(history.user))]),_vm._v(" "+_vm._s(history.operationType.toLowerCase())+"d "+_vm._s(history.entityType.toLowerCase())+": ")]),_c('ul',{staticClass:"changes-list"},_vm._l((_vm.getChanges(
                history.entityType.toLowerCase(),
                history.changes,
                history.operationType.toLowerCase()
              )),function(change,index){return _c('li',{key:index,staticClass:"changes-list__item"},[(Object.values(change)[0])?[(
                    history.operationType.toLowerCase() === 'create' &&
                    history.entityType.toLowerCase() === 'load'
                  )?[_vm._l((change.load),function(loadItem,index){return _c('div',{key:'loadInfo-' + index},[(Object.values(loadItem)[0])?[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(Object.keys(loadItem)[0])+": ")]),_c('span',[_vm._v(_vm._s(Object.values(loadItem)[0]))])]:_vm._e()],2)}),_c('p',{staticClass:"section-subtitle"},[_vm._v("Line Items:")]),_vm._l((change.lineItems),function(lineItem,index){return _c('div',{key:'lineItem-' + index,staticClass:"line-items-list"},[(index !== 0)?_c('hr',{staticClass:"line-items-divider"}):_vm._e(),_vm._l((Object.keys(lineItem)),function(key,lineItemIndex){return _c('div',{key:lineItemIndex},[_c('strong',[_vm._v(_vm._s(key)+": ")]),_c('span',[_vm._v(_vm._s(lineItem[key]))])])})],2)})]:[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(Object.keys(change)[0])+": ")]),_c('span',[_vm._v(_vm._s(Object.values(change)[0]))])]]:_vm._e()],2)}),0)])}),1),(_vm.notFound)?_c('div',{staticClass:"load-history__not-found"},[_c('p',[_vm._v(_vm._s(_vm.$t("myLoads.myLoadsDetail.nohistoriesFound")))])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }