<template>
  <div class="detail-claim-dispute">
    <div class="detail-claim-dispute__header">
      <div class="detail-claim-dispute__title">
        <h3>{{ title }}</h3>
      </div>
      <div class="detail-claim-dispute__button">
        <button class="close-button" @click="$emit('closeModal')" type="button">
          <i class="close-button__icon ion ion-close-circle-outline"></i>
        </button>
      </div>
    </div>
    <hr />
    <div class="detail-claim-dispute__content">
      <section class="claim-modal-row claim-modal-row--first-row">
        <!-- <div class="info-container">
          <p>{{ $t("claimOrDispute.type") }}</p>
          <span>{{ infoClaimOrDisp[index].category }}</span>
        </div> -->
        <div class="claim-modal-row__left-container">
          <div class="info-container">
            <p class="info-container__reason-text">{{ $t("claimOrDispute.reason") }}</p>
            <span>{{ infoClaimOrDisp[index].reason }}</span>
          </div>
          <div class="info-container-text info-container-text--documents-container">
            <p>{{ $t("claimOrDispute.documents") }}</p>
            <ul class="documents-list">
              <li
                v-for="(option, index) in infoClaimOrDisp[index].attachments"
                :key="index"
                class="documents-list__item"
              >
                <a :href="option.url" target="_blank">{{ option.filename }}</a>
              </li>
            </ul>
            <br />
          </div>
        </div>
        <div class="claim-modal-row__right-container">
          <div class="info-container">
            <p class="description-label__name">{{ $t("claimOrDispute.repAssigned") }}</p>
            <span class="description-label__info">{{ infoClaimOrDisp[index].review }}</span>
          </div>
          <div class="description-label">
            <span class="description-label__name">{{ $t("claimOrDispute.status") }}</span>
            <span class="description-label__info"
              >{{
                infoClaimOrDisp[index].review
                  ? infoClaimOrDisp[index].review
                  : $t("claimOrDispute.reviewing")
              }}
            </span>
            <span class="description-label__info--divider"
              >{{ infoClaimOrDisp[index].status }}
            </span>
          </div>
        </div>
      </section>
      <section class="claim-modal-row claim-modal-row--second-row">
        <div class="info-container-text">
          <p>{{ $t("claimOrDispute.supporting") }}</p>
          <div>{{ infoClaimOrDisp[index].text }}.</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DetailClaimOrDispute",
  emits: ["closeModal"],
  props: {
    loadById: Object,
  },
  data() {
    return {
      reviewclaimOrDispute: null,
      index: null,
      title: null,
      infoClaimOrDisp: null,
    };
  },
  computed: {
    ...mapGetters({ claimOrDispute: "claims/getInfoClaimOrDispute" }),
  },
  watch: {
    claimOrDispute: {
      immediate: true,
      handler() {
        // "Any billing inquiry" refers to dispute category
        // "Lost or damage cargo" refers to claim category
        this.infoClaimOrDisp = this.$store.getters["claims/getInfoClaimOrDispute"];
        // eslint-disable-next-line operator-linebreak
        this.reviewclaimOrDispute = this.$store.getters["claims/getReviewClaimOrDispute"];
        if (this.reviewclaimOrDispute === "showLostOrDamage") {
          this.index = this.infoClaimOrDisp.findIndex((el) => el.category === "claim");
          this.title = this.$t("claimOrDispute.reviewLostDamaged");
        } else {
          this.index = this.infoClaimOrDisp.findIndex((el) => el.category === "dispute");
          this.title = this.$t("claimOrDispute.invoiceInquiry");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-claim-dispute {
  width: 100%;
  font-family: $font-family-portal;
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
  }
}
hr {
  width: 100%;
  margin-top: 0;
  margin-bottom: 18px;
}
.close-button {
  @include modals-close-button;
}
.detail-claim-dispute {
  font-family: $font-family-portal;
  color: $color-primary-company;
  padding: 1%;
  width: 100%;
}
.claim-modal-row {
  &--first-row {
    display: grid;
    grid-template-columns: 60% 40%;
  }
  &--second-row {
    margin-bottom: 20px;
  }
}

.info-container {
  color: $color-primary-company;
  margin-bottom: 20px;
  min-height: 50px;
  p {
    font-weight: bold;
    margin-bottom: 2px;
    display: flex;
    flex-wrap: wrap;
  }
  div {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.info-container-text {
  color: $color-primary-company;
  p {
    font-weight: bold;
    margin-bottom: 2px;
    display: flex;
    flex-wrap: wrap;
  }
  div {
    width: 97%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.describe-dispute-claim {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.status-dispute-claim {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.description-label {
  width: 90%;
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  &__name {
    font-weight: bold;
  }
  &__info {
    max-height: 180px;
    overflow: scroll;
    &--divider {
      border-top: 1px solid $color-border-container;
      margin-top: 1rem;
      padding-top: 0.8rem;
    }
  }
}

.documents-list {
  padding-left: 18px;
  margin: 0px;
  width: 95%;
  &__item {
    // list-style-type: none;
    text-decoration: underline;
    & a {
      color: $color-primary-company;
    }
  }
}

// CSS for the scroll
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $color-gray-light;
  border: 2.1px solid $color-gray-dark;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-gray-dark;
}
::-webkit-scrollbar-corner {
  background-color: $color-gray-light;
}

@media (max-width: 500px) {
  .detail-claim-dispute {
    &__content {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
</style>
