<template>
  <div
    class="documents-viewer"
    :class="[
      previewMode ? 'closed-viewer' : 'opened-viewer',
      isResponsiveMode ? 'is-responsive' : '',
    ]"
    :style="previewMode && src ? 'position: relative' : ''"
  >
    <div class="go-back-icon" v-show="!previewMode" @click="hideViewer()">
      <i class="ion ion-close-circle-outline"></i>
    </div>
    <div class="paginator col-12 d-flex justify-content-center mb-3" v-if="!previewMode">
      <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
        <b-input-group>
          <div class="paginator-content d-flex">
            <b-input-group-prepend>
              <b-button @click="previous()" :class="previousLogic">
                <i class="ion ion-chevron-back-outline"></i>
              </b-button>
            </b-input-group-prepend>
            <input type="number" v-model="page" :max="totalPage" />
            <b-input-group-append>
              <b-input-group-text variant="outline-light">/ {{ totalPage }}</b-input-group-text>
              <b-button @click="next()" :class="nextLogic">
                <i class="ion ion-chevron-forward-outline"></i>
              </b-button>
            </b-input-group-append>
          </div>
          <b-input-group-append>
            <button class="button-action" @click="downloadDocument()">
              <i class="ion ion-cloud-download-outline"></i>Download
            </button>
          </b-input-group-append>
          <b-input-group-append v-if="!tokenInfo.is_branded">
            <button class="button-action" @click="shareDocument()">
              <i class="ion ion-mail-outline"></i>Share
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-button-toolbar>
    </div>
    <div
      class="buttons-container"
      v-show="showButtonToolbar && previewMode && !isResponsiveMode && src"
    >
      <button class="buttons-container__button" @click="openViewer()">
        <i class="ion ion-expand-outline"></i>
      </button>
      <button class="buttons-container__button" @click="downloadDocument()">
        <i class="ion ion-cloud-download-outline"></i>
      </button>
      <button class="buttons-container__button" @click="shareDocument()">
        <i class="ion ion-mail-outline"></i>
      </button>
    </div>
    <slot name="viewer-render"></slot>
  </div>
</template>

<script>
export default {
  name: "DocumentViewer",
  props: {
    previewMode: Boolean,
    showButtonToolbar: Boolean,
    tokenInfo: Object,
    page: Number,
    numPage: Number,
    totalPage: Number,
    currentPage: Number,
    disable: Boolean,
    isResponsiveMode: Boolean,
    src: String
  },
  computed: {
    previousLogic() {
      if (this.page === 1) {
        return "disabled";
      }
      return "enabled";
    },
    nextLogic() {
      if (this.page === this.totalPage) {
        return "disabled";
      }
      return "enabled";
    },
  },
  methods: {
    openViewer() {
      this.$emit("documentOpened");
      this.$emit("disableScroll");
    },
    hideViewer() {
      this.$emit("viewerHidden");
      this.$emit("enableScroll");
    },
    downloadDocument() {
      this.$emit("downloadSelectedDocument");
    },
    shareDocument() {
      this.$emit("shareDocument");
    },
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-viewer {
  // position: relative;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 15px;
  background-color: $color-white;
  overflow: hidden;
  &.opened-viewer {
    position: fixed;
    width: 100%;
    height: 100vh;
    padding: 2.5%;
    right: 0;
    top: 0;
    margin: 0px;
    border-radius: 0px;
    overflow: auto;
    z-index: 999;
    background: rgba($color: #000000, $alpha: 0.4);
  }
  &.is-responsive {
    height: 180px;
  }
}
.buttons-container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  z-index: 99;
  margin: 10px;
  &__button {
    width: 60px;
    height: 30px;
    border-radius: 7px;
    margin-left: 5px;
    background: $color-white;
    color: $color-primary-company;
    border: 1px solid $color-border-container;
    &:hover {
      color: $color-white;
      background: $color-primary-company;
      transition: 0.2s all;
      border: $color-white;
    }
  }
}
.go-back-icon {
  position: absolute;
  right: 20px;
  color: white;
  cursor: pointer;
  z-index: 999;
  i {
    font-size: 30px;
    font-weight: bold;
  }
}
.button-action {
  display: flex;
  align-items: center;
  border-left: 1px solid rgb(207, 207, 207);
  color: $color-border-container;
  @include font-small-button-text;
  font-weight: 400;
  padding: 8px;
  padding-right: 10px;
  i {
    margin-right: 5px;
    font-size: 15px;
  }
}
/* components custom styles */
.disabled {
  pointer-events: none;
}
.paginator {
  text-align: center;
  .input-group {
    justify-content: center;
  }
}
input {
  width: 45px;
  outline: none;
  border: none;
  text-align: center;
  pointer-events: none;
  color: $color-primary-company;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
button {
  background: $color-white;
  color: $color-primary-company;
  border: none;
}
button:hover {
  background: $color-primary-company;
  color: $color-white;
}
</style>
